export const FW_UNKNOWN = -1;
export const FW_KRC = 1;
export const FW_MF1M = 2;
export const FW_TUD2 = 3;
export const FW_TUD3 = 4;
export const FW_TP2020 = 5;
export const FW_UD2301 = 6;
export const FW_UD2303 = 7;
export const FW_UD3701 = 8;
export const FW_UT1M = 9;
export const FW_UT1MIP = 10;
export const FW_UT1MST = 11;
export const FW_UT3EMA = 12;
export const FW_UT2A = 13;
export const FW_ARS1 = 14;
export const FW_LEEB = 15;
export const FW_LEEB_PLAIN = 16;
export const FW_LAB_UCI = 17;
export const FW_LAB_DPM = 18;
export const FW_EDDYSENSE = 19;
export const FW_LAB_UCI_PRO = 20;
export const FW_DEEP_MASTER = 21;
export const FW_TLAB = 22;
export const FW_U2_USB = 23;
export const FW_U2_L_USB = 24;
export const FW_U2_XS_USB = 25;

export const FW_DEVICES = [
    FW_TP2020,
    FW_TUD2,
    FW_TUD3,
    FW_KRC,
    FW_MF1M,
    FW_UD2301,
    FW_UD2303,
    FW_UD3701,       // UD3701 fw update should work only with new devices
    FW_UT1M,
    FW_UT1MIP,
    FW_UT1MST,
    FW_UT3EMA,
    FW_UT2A,
    FW_ARS1,
    FW_LAB_UCI,
    FW_LAB_UCI_PRO,
    FW_LEEB,
    FW_LEEB_PLAIN,
    FW_LAB_DPM,
    FW_EDDYSENSE,
    FW_DEEP_MASTER,
    FW_TLAB,
    FW_U2_USB,
    FW_U2_L_USB,
    FW_U2_XS_USB
];
